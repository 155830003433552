import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout, Affix, FloatButton, message } from 'antd';

import { Suspense } from 'react';
import Cookies from "universal-cookie";

import { ParseUserToken } from "./components/backend/parseUserToken"
import { MenuMain } from "./components/menus/main"

import {
    CanCreateAccessTokens,
    CanSeeAccessTokens, CanSeeEvents,
    CanSeeProducts,
    CanSeeSlim4,
    IsDeveloper
} from "./components/backend/backendRoles";
import { Spinner } from "./components/spinners";

import { NotFound } from "./pages/notFound";
import { NoAccess } from "./pages/noAccess";
import { MainPage } from './pages/mainPage';
import { Login } from './pages/login';
import { MustChangePassword } from "./pages/mustChangePassword";
import { RestorePassword } from "./pages/restorePassword";

import { Profile } from './pages/profile/profile';
import { ProfileSettings } from './pages/profile/profileSettings';
import { ProfileSecurity } from "./pages/profile/profileSecurity";
import { MessagesList } from "./pages/profile/profileMessages";

import { Admin } from "./pages/admin/admin";
import { AdminUsersList } from "./pages/admin/users";
import { AdminUsersListClickUp } from "./pages/admin/usersClickUp";
import { AdminUserEditV2 } from "./pages/admin/adminUserEditV2";
import { UserCreate } from './pages/admin/userRegister';
import { AdminSessions } from "./pages/admin/sessions";
import { AdminSessionsSession } from "./pages/admin/sessionsSession";
import { FailedLogins } from "./pages/admin/failedLogins";
import { IpAddresses } from "./pages/admin/ipAddresses";

import { ProductListV2 } from "./pages/productsv2/productsListV2";
import { Products } from "./pages/productsv2/products";
import { Product } from "./pages/productsv2/product";
import { ProductCached } from "./pages/productsv2/productCached";
import { ProductMoreFlo } from "./pages/productsv2/productMoreFlo";
import { ProductsPricesDynamic } from "./pages/products/prices/dynamic";
import { ProductsPrices } from "./pages/products/prices/prices";

import { Brands } from "./pages/productsv2/brands";
import { Categories } from "./pages/productsv2/categories";
import { Countries } from "./pages/productsv2/countries";

import { CampaignsV2 } from "./pages/campaignsV2/campaignsV2";
import { CampaignCreateV2 } from "./pages/campaignsV2/createCampaignV2";
import { CampaignsClickUp } from './pages/campaignsV2/campaignsClickUp';
import { GetCampaignV2 } from "./pages/campaignsV2/getCampaignV2";
import { CampaignLogsV2 } from "./pages/campaignsV2/getLogsV2";

import { Slim4StatusPage } from "./pages/slim4/slim4StatusPage";
import { Slim4CacheProduct } from './pages/slim4/slim4CacheProduct';
import { Slim4CacheOrder } from './pages/slim4/slim4CacheOrder';
import { Slim4CacheOrderHistorical } from './pages/slim4/slim4CacheOrderHistorical.jsx';
import { Slim4CacheTransaction } from './pages/slim4/slim4CacheTransaction.jsx';

import { Moreflo } from "./pages/moreflo/moreflo";
import { MorefloPipelineHistory } from "./pages/moreflo/morefloPipelineHistory";

import { DeveloperMain } from "./pages/developer/developerMain";
import { DeveloperCosts } from "./pages/developer/costs/developerCosts";
import { DeveloperCostsClouds } from "./pages/developer/costs/developerCostsClouds.jsx";
import { DeveloperHealthmonitorTablesizes } from "./pages/developer/healthmonitor/developerHealthmonitorTablesizes";
import { DeveloperHealthmonitorIndexes } from "./pages/developer/healthmonitor/developerHealthmonitorIndexes";
import { DeveloperProducts } from "./pages/developer/products/developerProducts";
import { DeveloperProductsFeeds } from "./pages/developer/products/developerProductFeeds";
import { DeveloperProductFeedsV1 } from "./pages/developer/products/developerProductFeedsV1";
import { DeveloperProductFeedsV2 } from "./pages/developer/products/developerProductFeedsV2";
import { DeveloperProductFeedsV2Logs } from "./pages/developer/products/developerProductFeedsV2Logs";
import { DeveloperProductReplicators } from "./pages/developer/products/developerProductReplicators";
import { DeveloperProductReplicatorsV1 } from "./pages/developer/products/developerProductReplicatorsV1";
import { DeveloperEndpointsList } from "./pages/developer/endpoints/developerEndpoints";
import { DeveloperEndpointsEndpoint } from "./pages/developer/endpoints/developerEndpointsEndpoint";
import { DeveloperAccessTokensList } from "./pages/developer/accessTokens/developerAccessTokens";
import { DeveloperAccessTokenAdd } from "./pages/developer/accessTokens/developerAccessTokenAdd";
import { Runs } from "./pages/developer/services/runs/runs.jsx";
import { Run } from "./pages/developer/services/runs/run.jsx";

import { LogsList } from "./pages/logs/logsAll";
import { EventsList } from "./pages/stat/events/eventsList";
import { RequestLogsList } from "./pages/logs/requestLogsAll";

import { Event } from "./pages/stat/events/event";
import { useEffect, useState } from "react";
import { GetRequestInternalAPI } from "./components/backend/getRequest";
import { getCookieDomain } from "./components/backend/backend";
import { NotificationInfo } from "./components/notifications";
import { ProductService } from "./pages/developer/services/products/service.jsx";
import { NotificationsService } from "./pages/developer/services/notifications/service.jsx";
import { AuthService } from "./pages/developer/services/auth/service.jsx";
import { LoggerService } from "./pages/developer/services/logger/service.jsx";
import { EcosystemService } from "./pages/developer/services/ecosystem/service.jsx";
import { DataImportsList } from "./pages/developer/dataimports/dataImports";


function App() {
    let authed = false
    let uid = undefined
    let name = undefined
    let avatar_link = undefined
    let grants = undefined
    let mustChangePassword = undefined

    let token = ParseUserToken()
    if (token !== undefined) {
        authed = true
        uid = token.ID
        name = token.Name
        avatar_link = token.AvatarLink
        grants = token.grants
        mustChangePassword = token.MustChangePassword
    }

    const { Header } = Layout;
    const cookies = new Cookies();
    const [messageAPI, contextHolder] = message.useMessage();

    const [unreadMessages, setUnreadMessages] = useState(0);
    const [lastNotifiedMessageTime, setLastNotifiedMessageTime] = useState("");

    const recountUnreads = () => { GetUnreads() }

    // Don't call more than once.
    const fetchUnreads = () => {
        if (!authed) {
            return
        }

        GetUnreads()

        setTimeout(fetchUnreads, 50000);
    }

    const GetUnreads = () => {
        GetRequestInternalAPI(
            `/api/v1/notifications/messages/self/unread_count`,
            null,
            null,
            null
        ).then(
            (result = { TotalUnreadMessages: 0, LastMessageTime: "" }) => {
                setUnreadMessages(result.TotalUnreadMessages)
                setLastNotifiedMessageTime(result.LastMessageTime)
            }
        )
    }

    useEffect(() => {
        if (unreadMessages > 0) {
            let lastMessage = cookies.get("lastMessage")

            if (lastMessage === "" || lastMessage !== lastNotifiedMessageTime) {
                if (lastNotifiedMessageTime !== "") {
                    NotificationInfo(`You have ${unreadMessages} unread message(s)`)

                    cookies.set('lastMessage', lastNotifiedMessageTime, {
                        domain: getCookieDomain(),
                        path: '/',
                        sameSite: 'none',
                        secure: true
                    });
                }
            }
        }
        // eslint-disable-next-line
    }, [lastNotifiedMessageTime]);

    useEffect(() => {
        fetchUnreads();
        // eslint-disable-next-line
    }, []);

    const [unresolvedEvents, setUnresolvedEvents] = useState(0);
    const [lastNotifiedEventTime, setLastNotifiedEventTime] = useState("");

    const recountEvents = () => { GetOpenedEvents() }

    // Don't call more than once.
    const fetchOpenedEvents = () => {
        if (!authed) {
            return
        }

        GetOpenedEvents()

        setTimeout(fetchOpenedEvents, 50000);
    }

    const GetOpenedEvents = () => {
        if (!CanSeeEvents(grants)) {
            return
        }

        GetRequestInternalAPI(
            `/api/v1/notifications/events/opened_count`,
            null,
            null,
            null
        ).then(
            (result = { TotalOpenedEvents: 0, LastEventTime: "" }) => {
                setUnresolvedEvents(result.TotalOpenedEvents)
                setLastNotifiedEventTime(result.LastEventTime)
            }
        )
    }

    useEffect(() => {
        if (unresolvedEvents > 0) {
            let lastEvent = cookies.get("lastEvent")

            console.log("lastEvent", lastEvent)
            console.log("lastNotifiedEventTime", lastNotifiedEventTime)
            if (lastEvent === "" || lastEvent !== lastNotifiedEventTime) {
                if (lastNotifiedEventTime !== "") {
                    NotificationInfo(`There are events that require attention: ${unresolvedEvents}`)

                    cookies.set('lastEvent', lastNotifiedEventTime, {
                        domain: getCookieDomain(),
                        path: '/',
                        sameSite: 'none',
                        secure: true
                    });
                }
            }
        }
        // eslint-disable-next-line
    }, [lastNotifiedEventTime]);

    useEffect(() => {
        fetchOpenedEvents();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="App">
            <Suspense fallback={<Spinner tip={""} />}>
                <BrowserRouter>
                    <Affix offsetTop={"0"}>
                        <Header>
                            <MenuMain
                                unreadMessages={unreadMessages}
                                unresolvedEvents={unresolvedEvents}
                                authed={authed}
                                id={uid}
                                name={name}
                                avatar_link={avatar_link}
                                grants={grants}
                            />
                        </Header>
                    </Affix>
                    <GetRoutes
                        authed={authed}
                        token={token}
                        grants={grants}
                        mustChangePassword={mustChangePassword}
                        messageAPI={messageAPI}
                        recountUnreads={recountUnreads}
                        recountEvents={recountEvents}
                    />
                </BrowserRouter>
            </Suspense>
            <FloatButton.BackTop />
            {contextHolder}
        </div>
    );
}

function GetRoutes(props) {
    if (props.authed === false) {
        return (
            <Routes>
                <Route path="/restore_password" element={<RestorePassword />} />
                <Route path="*" element={<Login />} />
            </Routes>
        )
    }

    if (props.mustChangePassword === true) {
        return (
            <Routes>
                <Route path="/profile/edit" element={<ProfileSettings token={props.token} />} />
                <Route path="*" element={<MustChangePassword />} />
            </Routes>
        )
    }

    if (props.authed === true) {
        return (
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/profile/edit"
                    element={<ProfileSettings messageAPI={props.messageAPI} token={props.token} />} />
                <Route path="/profile/security" element={<ProfileSecurity grants={props.grants} token={props.token} />} />
                <Route path="/profile/messages"
                    element={<MessagesList messageAPI={props.messageAPI} grants={props.grants} token={props.token}
                        recountUnreads={props.recountUnreads} />} />
                <Route path="/profile/:id" element={<Profile uid={props.token.ID} />} />

                <Route path="/products" element={<Products />} />
                <Route path="/products/list" element={<ProductListV2 />} />
                <Route path="/products/prices/dynamic" element={<ProductsPricesDynamic messageAPI={props.messageAPI} />} />
                <Route path="/products/prices" element={<ProductsPrices />} />
                <Route path="/products/:id/cached" element={<ProductCached />} />
                <Route path="/products/:itemSKU/more_flo" element={<ProductMoreFlo token={props.token} />} />
                <Route path="/products/:id" element={<Product />} />
                <Route path="/brands/list" element={<Brands />} />
                <Route path="/categories/list" element={<Categories />} />
                <Route path="/countries/list" element={<Countries />} />

                <Route path="/moreflo" element={<Moreflo />} />
                <Route path="/moreflo/history" element={<MorefloPipelineHistory />} />

                <Route path="/campaigns/v2" element={<CampaignsV2 />} />
                <Route path="/campaigns/v2/create" element={<CampaignCreateV2 />} />
                <Route path="/campaigns/v2/import_click_up" element={<CampaignsClickUp />} />
                <Route path="/campaigns/v2/logs" element={<CampaignLogsV2 grants={props.grants} />} />
                <Route path="/campaigns/v2/:id" element={<GetCampaignV2 messageAPI={props.messageAPI} />} />

                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/sessions" element={<AdminSessions token={props.token} />} />
                <Route path="/admin/sessions/:id" element={<AdminSessionsSession />} />
                <Route path="/admin/users" element={<AdminUsersList token={props.token} />} />
                <Route path="/admin/users/click-up" element={<AdminUsersListClickUp />} />
                <Route path="/admin/users/edit/:id" element={<AdminUserEditV2 token={props.token} />} />
                <Route path="/admin/users/create" element={<UserCreate />} />
                <Route path="/admin/failed_logins" element={<FailedLogins />} />
                <Route path="/admin/ip_addresses/list" element={<IpAddresses />} />

                <Route path="/stat/logs" element={<LogsList grants={props.grants} token={props.token} />} />
                <Route path="/stat/request_logs"
                    element={<RequestLogsList grants={props.grants} sessionID={props.token.SessionID} />} />
                <Route path="/stat/events" element={<EventsList grants={props.grants} />} />
                <Route path="/stat/events/:id" element={<Event messageAPI={props.messageAPI} recountEvents={props.recountEvents} grants={props.grants} token={props.token} />} />

                {
                    CanSeeSlim4(props.grants) === true
                        ?
                        <>
                            <Route path="/slim4" element={<Slim4StatusPage />} />
                            <Route path="/slim4/cache_product" element={<Slim4CacheProduct />} />
                            <Route path="/slim4/cache_order" element={<Slim4CacheOrder />} />
                            <Route path="/slim4/cache_order_historical" element={<Slim4CacheOrderHistorical />} />
                            <Route path="/slim4/cache_transaction" element={<Slim4CacheTransaction />} />
                        </>
                        :
                        <>
                            <Route path="/slim4" element={<NoAccess />} />
                            <Route path="/slim4/*" element={<NoAccess />} />
                        </>
                }
                {DeveloperRoutes(props.token.SessionID, props.grants, props.token, props.messageAPI)}

                <Route path="*" element={<NotFound />} />
            </Routes>
        )
    }

    return (
        <Routes>
        </Routes>
    )
}

function DeveloperRoutes(sessionID, grants = {}, token, messageAPI) {
    return (
        <>
            {
                IsDeveloper(grants)
                    ?
                    <>
                        {
                            CanSeeAccessTokens(grants) === true
                                ?
                                <>
                                    <Route path="/developer/access-tokens/list"
                                        element={<DeveloperAccessTokensList sessionID={sessionID}
                                            grants={grants} />} />
                                    {
                                        CanCreateAccessTokens(grants) === true
                                            ?
                                            <Route path="/developer/access-tokens/add"
                                                element={<DeveloperAccessTokenAdd grants={grants} />} />
                                            :
                                            <></>
                                    }
                                </>
                                :
                                <>
                                    <Route path="/developer/access-tokens/*" element={<NoAccess />} />
                                </>
                        }
                        <Route path="/developer/services/runs" element={<Runs />} />
                        <Route path="/developer/services/runs/:id" element={<Run />} />
                        <Route path="/developer/services/auth_service" element={<AuthService />} />
                        <Route path="/developer/services/logger_service" element={<LoggerService />} />
                        <Route path="/developer/services/notify_service" element={<NotificationsService />} />
                        <Route path="/developer/services/products_service" element={<ProductService />} />
                        <Route path="/developer/services/eco_service" element={<EcosystemService />} />

                        <Route path="/developer/dataimports" element={<DataImportsList />} />

                        <Route path="/developer/healthmonitor/tablesizes"
                            element={<DeveloperHealthmonitorTablesizes />} />
                        <Route path="/developer/healthmonitor/indexes" element={<DeveloperHealthmonitorIndexes />} />
                        <Route path="/developer/costs" element={<DeveloperCosts />} />
                        <Route path="/developer/costs/clouds" element={<DeveloperCostsClouds />} />
                        <Route path="/developer/endpoints" element={<DeveloperEndpointsList />} />
                        <Route path="/developer/endpoints/:id" element={<DeveloperEndpointsEndpoint />} />
                        {
                            CanSeeProducts(grants) === true
                                ?
                                <>
                                    <Route path="/developer/products" element={<DeveloperProducts />} />
                                    <Route path="/developer/products/feeds" element={<DeveloperProductsFeeds />} />
                                    <Route path="/developer/products/feeds/v1" element={<DeveloperProductFeedsV1 />} />
                                    <Route path="/developer/products/feeds/v2" element={<DeveloperProductFeedsV2 />} />
                                    <Route path="/developer/products/feeds/v2/logs"
                                        element={<DeveloperProductFeedsV2Logs />} />
                                    <Route path="/developer/products/replicators"
                                        element={<DeveloperProductReplicators />} />
                                    <Route path="/developer/products/replicators/v1"
                                        element={<DeveloperProductReplicatorsV1 />} />
                                </>
                                :
                                <>
                                    <Route path="/developer/products/*" element={<NoAccess />} />
                                </>
                        }
                        <Route path="/developer" element={<DeveloperMain />} />

                    </>
                    :
                    <>
                        <Route path="/developer/*" element={<NoAccess />} />
                    </>
            }
        </>
    )
}

export default App;