import {
    AndroidOutlined,
    BarcodeOutlined,
    HomeOutlined,
    FallOutlined,
    UnlockOutlined,
    TeamOutlined,
    UserOutlined, SolutionOutlined, BarChartOutlined
} from "@ant-design/icons";
import React from "react";

export const pages = {
    "home": {
        href: '/',
        title: <HomeOutlined />,
    },
    "admin": {
        href: '/admin',
        title: (
            <>
                <UnlockOutlined />
                <span>Admin panel</span>
            </>
        ),
    },
    "admin_users": {
        href: '/admin/users',
        title: (
            <>
                <TeamOutlined />
                <span>Users</span>
            </>
        ),
    },
    "admin_sessions": {
        href: '/admin/sessions',
        title: (
            <>
                <SolutionOutlined />
                <span>Sessions</span>
            </>
        ),
    },
    "profile": {
        href: '/profile',
        title: (
            <>
                <UserOutlined />
                <span>Profile</span>
            </>
        ),
    },
    "campaigns": {
        href: '/campaigns/v2',
        title: (
            <>
                <FallOutlined />
                <span>Campaigns</span>
            </>
        ),
    },
    "products": {
        href: '/products',
        title: (
            <>
                <BarcodeOutlined />
                <span>Products</span>
            </>
        ),
    },
    "prices": {
        href: '/products/prices',
        title: (
            <>
                <span>Prices</span>
            </>
        ),
    },
    "moreflo": {
        href: '/moreflo',
        title: (
            <>
                <span>MoreFlo</span>
            </>
        ),
    },
    "slim4": {
        href: '/slim4',
        title: (
            <>
                <span>Slim4</span>
            </>
        ),
    },
    "developer_area": {
        href: '/developer',
        title: (
            <>
                <AndroidOutlined />
                <span>Developer area</span>
            </>
        ),
    },
    "developer_area_services": {
        href: '/developer/services',
        title: (
            <>
                <span>Services</span>
            </>
        ),
    },
    "developer_area_services_runs": {
        href: '/developer/services/runs',
        title: (
            <>
                <span>Runs</span>
            </>
        ),
    },
    "developer_area_access_tokens": {
        href: '/developer/access-tokens/list',
        title: (
            <>
                <span>Access tokens</span>
            </>
        ),
    },
    "developer_area_endpoints": {
        href: '/developer/endpoints',
        title: (
            <>
                <span>Endpoints</span>
            </>
        ),
    },
    "developer_area_sentry": {
        href: '/developer/sentry',
        title: (
            <>
                <span>Sentry</span>
            </>
        ),
    },
    "developer_area_costs": {
        href: '/developer/costs',
        title: (
            <>
                <span>Costs</span>
            </>
        ),
    },
    "developer_area_health_monitor": {
        href: '/developer/health_monitor',
        title: (
            <>
                <span>Health Monitor</span>
            </>
        ),
    },
    "developer_area_health_monitor/tablesizes": {
        href: '/developer/health_monitor/tables_sizes',
        title: (
            <>
                <span>Tables sizes</span>
            </>
        ),
    },
    "developer_area_health_monitor/indexes": {
        href: '/developer/health_monitor/indexes',
        title: (
            <>
                <span>Indexes</span>
            </>
        ),
    },
    "developer_area_products": {
        href: '/developer/products',
        title: (
            <>
                <BarcodeOutlined />
                <span>Products</span>
            </>
        ),
    },
    "developer_area_products_feeds": {
        href: '/developer/products/feeds',
        title: (
            <>
                <span>Feeds</span>
            </>
        ),
    },
    "developer_area_products_feeds_v2": {
        href: '/developer/products/feeds/v2',
        title: (
            <>
                <span>Feeds V2</span>
            </>
        ),
    },
    "developer_area_products_replicators": {
        href: '/developer/products/replicators',
        title: (
            <>
                <span>Replicators</span>
            </>
        ),
    },
    "stat": {
        href: '/stat',
        title: (
            <>
                <BarChartOutlined />
                <span>Statistic</span>
            </>
        ),
    },
    "stat_events": {
        href: '/stat/events',
        title: (
            <>
                <span>Events</span>
            </>
        ),
    },
}
/*
export function Breadcrumbs(props) {
    const location = useLocation();
    const items = [
        {
            href: '/',
            title: <HomeOutlined/>,
        }
    ]
    let pathArray = location.pathname.split('/')
    let pathAccumulated = ""
    let name = ""
    for (let i = 1; i < pathArray.length - 1; i++) {
        pathAccumulated += `/${pathArray[i]}`
        if (pages[pathAccumulated] !== undefined) {
            name = pages[pathAccumulated].Title
        } else {
            name = pathArray[i].charAt(0).toUpperCase() + pathArray[i].slice(1)
        }
        if (names[name] !== undefined) {
            name = names[name]
        }

        items.push({
            title: <>{name}</>,
            href: pages[pathAccumulated] !== undefined ? pages[pathAccumulated].Link : pathAccumulated
        })
    }

    let lastTile = ""
    if (props.lastTitle === undefined) {
        if (pages[pathAccumulated + "/" + pathArray[pathArray.length - 1]] !== undefined) {
            lastTile = pages[pathAccumulated].Title
        } else {
            lastTile = pathArray[pathArray.length - 1].charAt(0).toUpperCase() + pathArray[pathArray.length - 1].slice(1)
        }
    } else {
        lastTile = props.lastTitle
    }
    if (lastTile !== "") {
        items.push({
            title: <>{names[lastTile] !== undefined ? names[lastTile] : lastTile}</>,
        })
    }

    return (
        <Breadcrumb
            items={items}
        />
    )
}*/